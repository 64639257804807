// Utility classes
// =============================================================================
$loopStart: 0;
$loopEnd: 20;

/*
 * content width classes
 */
@for $i from $loopStart through $loopEnd {
	@if $i == 0 {
		.utl-w-auto {
			width: auto !important;
		}
	} @else {
		.utl-w-#{5 * $i} {
			width: 5% * $i !important;
		}
	}
}

/*
 * margin & padding classes
 *
 * if the font size of self or the closest parent element is "16px", the class name ".utl-mt-5" is equivalent to "margin-top: 5px;"
 * every time the font size of the closest parent element changes, the margin value of ".utl-mt-x" also changes relatively
 * also, the padding value changes in the same way as above
 */
/* Utility margin */
.utl-m-auto {
	margin: auto !important;
}
.utl-m-0 {
	margin: 0 !important;
}
/* margin top */
@for $i from $loopStart through $loopEnd {
	@if $i == 0 {
		.utl-mt-#{$i} {
			margin-top: $i !important;
		}
	} @else {
		.utl-mt-#{5 * $i} {
			margin-top: (0.5 / $basePcFz + 0em) * $i !important;
		}
	}
}
/* margin bottom */
@for $i from $loopStart through $loopEnd {
	@if $i == 0 {
		.utl-mb-#{$i} {
			margin-bottom: $i !important;
		}
	} @else {
		.utl-mb-#{5 * $i} {
			margin-bottom: (0.5 / $basePcFz + 0em) * $i !important;
		}
	}
}
/* margin right */
.utl-mr-auto {
	margin-right: auto !important;
}
@for $i from $loopStart through $loopEnd {
	@if $i == 0 {
		.utl-mr-#{$i} {
			margin-right: $i !important;
		}
	} @else {
		.utl-mr-#{5 * $i} {
			margin-right: (0.5 / $basePcFz + 0em) * $i !important;
		}
	}
}
/* margin left */
.utl-ml-auto {
	margin-left: auto !important;
}
@for $i from $loopStart through $loopEnd {
	@if $i == 0 {
		.utl-ml-#{$i} {
			margin-left: $i !important;
		}
	} @else {
		.utl-ml-#{5 * $i} {
			margin-left: (0.5 / $basePcFz + 0em) * $i !important;
		}
	}
}
/* margin X-axis */
@for $i from $loopStart through $loopEnd {
	@if $i == 0 {
		.utl-mx-auto {
			margin: {
				right: auto !important;
				left: auto !important;
			}
		}
		.utl-mx-#{$i} {
			margin: {
				right: $i !important;
				left: $i !important;
			}
		}
	} @else {
		.utl-mx-#{5 * $i} {
			margin: {
				right: (0.5 / $basePcFz + 0em) * $i !important;
				left: (0.5 / $basePcFz + 0em) * $i !important;
			}
		}
	}
}
/* margin Y-axis */
@for $i from $loopStart through $loopEnd {
	@if $i == 0 {
		.utl-my-auto {
			margin: {
				top: auto !important;
				bottom: auto !important;
			}
		}
		.utl-my-#{$i} {
			margin: {
				top: $i !important;
				bottom: $i !important;
			}
		}
	} @else {
		.utl-my-#{5 * $i} {
			margin: {
				top: (0.5 / $basePcFz + 0em) * $i !important;
				bottom: (0.5 / $basePcFz + 0em) * $i !important;
			}
		}
	}
}

/* Utility padding */
.utl-p-0 {
	padding: 0 !important;
}
/* padding top */
@for $i from $loopStart through $loopEnd {
	@if $i == 0 {
		.utl-pt-#{$i} {
			padding-top: $i !important;
		}
	} @else {
		.utl-pt-#{5 * $i} {
			padding-top: (0.5 / $basePcFz + 0em) * $i !important;
		}
	}
}
/* padding bottom */
@for $i from $loopStart through $loopEnd {
	@if $i == 0 {
		.utl-pb-#{$i} {
			padding-bottom: $i !important;
		}
	} @else {
		.utl-pb-#{5 * $i} {
			padding-bottom: (0.5 / $basePcFz + 0em) * $i !important;
		}
	}
}
/* padding right */
@for $i from $loopStart through $loopEnd {
	@if $i == 0 {
		.utl-pr-#{$i} {
			padding-right: $i !important;
		}
	} @else {
		.utl-pr-#{5 * $i} {
			padding-right: (0.5 / $basePcFz + 0em) * $i !important;
		}
	}
}
/* padding left */
@for $i from $loopStart through $loopEnd {
	@if $i == 0 {
		.utl-pl-#{$i} {
			padding-left: $i !important;
		}
	} @else {
		.utl-pl-#{5 * $i} {
			padding-left: (0.5 / $basePcFz + 0em) * $i !important;
		}
	}
}
/* padding X-axis */
@for $i from $loopStart through $loopEnd {
	@if $i == 0 {
		.utl-px-#{$i} {
			padding: {
				right: $i !important;
				left: $i !important;
			}
		}
	} @else {
		.utl-px-#{5 * $i} {
			padding: {
				right: (0.5 / $basePcFz + 0em) * $i !important;
				left: (0.5 / $basePcFz + 0em) * $i !important;
			}
		}
	}
}
/* padding Y-axis */
@for $i from $loopStart through $loopEnd {
	@if $i == 0 {
		.utl-py-#{$i} {
			padding: {
				top: $i !important;
				bottom: $i !important;
			}
		}
	} @else {
		.utl-py-#{5 * $i} {
			padding: {
				top: (0.5 / $basePcFz + 0em) * $i !important;
				bottom: (0.5 / $basePcFz + 0em) * $i !important;
			}
		}
	}
}

/*
 * text classes
 */
.utl-col {
	&-red01 {
		color: $siteCol-red01;
	}
	&-blu01 {
		color: $siteCol-blu01;
	}
	&-blu02 {
		color: $siteCol-d-blu01;
	}
	&-blu03 {
		color: $siteCol-l-blu03;
	}
	&-blu04 {
		color: $siteCol-l-blu04;
	}
	&-blu05 {
		color: $siteCol-l-blu05;
	}
	&-yel01 {
		color: $siteCol-yel01;
	}
	&-wht {
		color: $siteCol-f-wht;
	}
}

.utl-fwn {
	font-weight: $fwn !important;
}
.utl-fwb {
	font-weight: $fwb !important;
}

$fwNsnsJpArr: (
	'thin': $fwNsnsJp-thin,
	'light': $fwNsnsJp-light,
	'regular': $fwNsnsJp-regular,
	'medium': $fwNsnsJp-medium,
	'bold': $fwNsnsJp-bold,
	'black': $fwNsnsJp-black
);
@each $fwName, $fwNum in $fwNsnsJpArr {
	.utl-fw-#{$fwNum},
	.utl-fwnsnsjp-#{$fwName} {
		font-weight: $fwNum !important;
	}
}

.utl-tal {
	text-align: left !important;
}
.utl-tar {
	text-align: right !important;
}
.utl-tac {
	text-align: center !important;
}
.utl-taj {
	text-align: justify !important;
}

/*
 * content display classes
 */
.utl-di {
	display: inline !important;
}
.utl-db {
	display: block !important;
}
.utl-dib {
	display: inline-block !important;
}
.utl-dflx {
	// display: -webkit-flex;
	display: flex !important;
}
.utl-diflx {
	// display: -webkit-inline-flex;
	display: inline-flex !important;
}

/*
 * position classes
 */
.utl-rltv {
	position: relative;
}
.utl-absl {
	position: absolute;
}
.utl-fxd {
	position: fixed;
}

/*
 * link disable
 */
.utl-nolnk {
	pointer-events: none;
}

/*
 * anchor block
 */
a {
	.utl-anch-blk &,
	&.utl-anch-blk {
		display: block;
	}
}

/*
 * text break
 */
.utl-br {
	display: inline-block;
	&-all {
		word-break: break-all;
	}
}

/*
 * text indent
 */
.utl-indent {
	text-indent: 1em;
}
.utl-neg-indent {
	margin-left: 1em;
	text-indent: -1em;
}

/*
 * image replacement
 */
.utl-ir {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

/*
 * mouse over action
 */
.utl-ovr {
	backface-visibility: hidden;
	.is-load & {
		transition: opacity $ovrDur $easeOutQuint;
	}
	.ua-mbl & {
		&:active {
			opacity: 0.4;
		}
	}
	body:not(.ua-mbl) & {
		&:hover {
			opacity: 0.4;
		}
	}
	&-brght {
		backface-visibility: hidden;
		.is-load & {
			transition: filter $ovrDur $easeOutQuint;
		}
		.ua-mbl & {
			&:active {
				filter: brightness(125%);
			}
		}
		body:not(.ua-mbl) & {
			&:hover {
				filter: brightness(125%);
			}
		}
	}
}

/*
 * add pointer effect
 */
.utl-ptr {
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*
 * add inertial scroll to mobile device
 */
.utl-i-scroll {
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
}

/*
 * scroll bar hidden
 */
.utl-scroll-bar-hdn {
	scrollbar-width: none;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}
}

/*
 * change svg color
 */
.utl-chg-svg-col {
	fill: currentColor;
}

/*
 * clear fix
 */
.utl-cf {
	&::before,
	&::after {
		content: '';
		display: table;
	}
	&::after {
		clear: both;
	}
}

/*
 * hide visually and from screen readers, but maintain layout
 */
.utl-invisible {
	visibility: hidden;
}

/*
 * hide visually and from screen readers
 */
.utl-hdn {
	display: none !important;
}
.utl-mbl-hdn {
	@include mediaQueries-maxW('mbl-l') { // mobile size = L //
		display: none !important;
	}
}
.utl-pc-hdn {
	@include mediaQueries-minW('pc-s') { // desktop size = S //
		display: none !important;
	}
}
.utl-tblt-hdn {
	@include mediaQueries-minW-to-maxW('tblt-s', 'tblt-l') { // tablet size = S - L //
		display: none !important;
	}
}

/*
 * hide only visually, but have it available for screen readers
 */
.utl-visually-hdn {
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
	overflow: hidden;
	position: absolute;
	clip: rect(0 0 0 0);
}

/*
 * extends the .utl-visually-hdn class to allow the element
 * to be focusable when navigated to via the keyboard
 */
.utl-visually-hdn-foc {
	@extend .utl-visually-hdn;
	&:active,
	&:focus {
		width: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		position: static;
		clip: auto;
	}
}