/* ==============================================================================================
   Utility classes
   ============================================================================================== */
/*
 * content width classes
 */
.utl-w-auto {
	width: auto !important;
}

.utl-w-5 {
	width: 5% !important;
}

.utl-w-10 {
	width: 10% !important;
}

.utl-w-15 {
	width: 15% !important;
}

.utl-w-20 {
	width: 20% !important;
}

.utl-w-25 {
	width: 25% !important;
}

.utl-w-30 {
	width: 30% !important;
}

.utl-w-35 {
	width: 35% !important;
}

.utl-w-40 {
	width: 40% !important;
}

.utl-w-45 {
	width: 45% !important;
}

.utl-w-50 {
	width: 50% !important;
}

.utl-w-55 {
	width: 55% !important;
}

.utl-w-60 {
	width: 60% !important;
}

.utl-w-65 {
	width: 65% !important;
}

.utl-w-70 {
	width: 70% !important;
}

.utl-w-75 {
	width: 75% !important;
}

.utl-w-80 {
	width: 80% !important;
}

.utl-w-85 {
	width: 85% !important;
}

.utl-w-90 {
	width: 90% !important;
}

.utl-w-95 {
	width: 95% !important;
}

.utl-w-100 {
	width: 100% !important;
}

/*
 * margin & padding classes
 *
 * if the font size of self or the closest parent element is "16px", the class name ".utl-mt-5" is equivalent to "margin-top: 5px;"
 * every time the font size of the closest parent element changes, the margin value of ".utl-mt-x" also changes relatively
 * also, the padding value changes in the same way as above
 */
/* Utility margin */
.utl-m-auto {
	margin: auto !important;
}

.utl-m-0 {
	margin: 0 !important;
}

/* margin top */
.utl-mt-0 {
	margin-top: 0 !important;
}

.utl-mt-5 {
	margin-top: 0.3125em !important;
}

.utl-mt-10 {
	margin-top: 0.625em !important;
}

.utl-mt-15 {
	margin-top: 0.9375em !important;
}

.utl-mt-20 {
	margin-top: 1.25em !important;
}

.utl-mt-25 {
	margin-top: 1.5625em !important;
}

.utl-mt-30 {
	margin-top: 1.875em !important;
}

.utl-mt-35 {
	margin-top: 2.1875em !important;
}

.utl-mt-40 {
	margin-top: 2.5em !important;
}

.utl-mt-45 {
	margin-top: 2.8125em !important;
}

.utl-mt-50 {
	margin-top: 3.125em !important;
}

.utl-mt-55 {
	margin-top: 3.4375em !important;
}

.utl-mt-60 {
	margin-top: 3.75em !important;
}

.utl-mt-65 {
	margin-top: 4.0625em !important;
}

.utl-mt-70 {
	margin-top: 4.375em !important;
}

.utl-mt-75 {
	margin-top: 4.6875em !important;
}

.utl-mt-80 {
	margin-top: 5em !important;
}

.utl-mt-85 {
	margin-top: 5.3125em !important;
}

.utl-mt-90 {
	margin-top: 5.625em !important;
}

.utl-mt-95 {
	margin-top: 5.9375em !important;
}

.utl-mt-100 {
	margin-top: 6.25em !important;
}

/* margin bottom */
.utl-mb-0 {
	margin-bottom: 0 !important;
}

.utl-mb-5 {
	margin-bottom: 0.3125em !important;
}

.utl-mb-10 {
	margin-bottom: 0.625em !important;
}

.utl-mb-15 {
	margin-bottom: 0.9375em !important;
}

.utl-mb-20 {
	margin-bottom: 1.25em !important;
}

.utl-mb-25 {
	margin-bottom: 1.5625em !important;
}

.utl-mb-30 {
	margin-bottom: 1.875em !important;
}

.utl-mb-35 {
	margin-bottom: 2.1875em !important;
}

.utl-mb-40 {
	margin-bottom: 2.5em !important;
}

.utl-mb-45 {
	margin-bottom: 2.8125em !important;
}

.utl-mb-50 {
	margin-bottom: 3.125em !important;
}

.utl-mb-55 {
	margin-bottom: 3.4375em !important;
}

.utl-mb-60 {
	margin-bottom: 3.75em !important;
}

.utl-mb-65 {
	margin-bottom: 4.0625em !important;
}

.utl-mb-70 {
	margin-bottom: 4.375em !important;
}

.utl-mb-75 {
	margin-bottom: 4.6875em !important;
}

.utl-mb-80 {
	margin-bottom: 5em !important;
}

.utl-mb-85 {
	margin-bottom: 5.3125em !important;
}

.utl-mb-90 {
	margin-bottom: 5.625em !important;
}

.utl-mb-95 {
	margin-bottom: 5.9375em !important;
}

.utl-mb-100 {
	margin-bottom: 6.25em !important;
}

/* margin right */
.utl-mr-auto {
	margin-right: auto !important;
}

.utl-mr-0 {
	margin-right: 0 !important;
}

.utl-mr-5 {
	margin-right: 0.3125em !important;
}

.utl-mr-10 {
	margin-right: 0.625em !important;
}

.utl-mr-15 {
	margin-right: 0.9375em !important;
}

.utl-mr-20 {
	margin-right: 1.25em !important;
}

.utl-mr-25 {
	margin-right: 1.5625em !important;
}

.utl-mr-30 {
	margin-right: 1.875em !important;
}

.utl-mr-35 {
	margin-right: 2.1875em !important;
}

.utl-mr-40 {
	margin-right: 2.5em !important;
}

.utl-mr-45 {
	margin-right: 2.8125em !important;
}

.utl-mr-50 {
	margin-right: 3.125em !important;
}

.utl-mr-55 {
	margin-right: 3.4375em !important;
}

.utl-mr-60 {
	margin-right: 3.75em !important;
}

.utl-mr-65 {
	margin-right: 4.0625em !important;
}

.utl-mr-70 {
	margin-right: 4.375em !important;
}

.utl-mr-75 {
	margin-right: 4.6875em !important;
}

.utl-mr-80 {
	margin-right: 5em !important;
}

.utl-mr-85 {
	margin-right: 5.3125em !important;
}

.utl-mr-90 {
	margin-right: 5.625em !important;
}

.utl-mr-95 {
	margin-right: 5.9375em !important;
}

.utl-mr-100 {
	margin-right: 6.25em !important;
}

/* margin left */
.utl-ml-auto {
	margin-left: auto !important;
}

.utl-ml-0 {
	margin-left: 0 !important;
}

.utl-ml-5 {
	margin-left: 0.3125em !important;
}

.utl-ml-10 {
	margin-left: 0.625em !important;
}

.utl-ml-15 {
	margin-left: 0.9375em !important;
}

.utl-ml-20 {
	margin-left: 1.25em !important;
}

.utl-ml-25 {
	margin-left: 1.5625em !important;
}

.utl-ml-30 {
	margin-left: 1.875em !important;
}

.utl-ml-35 {
	margin-left: 2.1875em !important;
}

.utl-ml-40 {
	margin-left: 2.5em !important;
}

.utl-ml-45 {
	margin-left: 2.8125em !important;
}

.utl-ml-50 {
	margin-left: 3.125em !important;
}

.utl-ml-55 {
	margin-left: 3.4375em !important;
}

.utl-ml-60 {
	margin-left: 3.75em !important;
}

.utl-ml-65 {
	margin-left: 4.0625em !important;
}

.utl-ml-70 {
	margin-left: 4.375em !important;
}

.utl-ml-75 {
	margin-left: 4.6875em !important;
}

.utl-ml-80 {
	margin-left: 5em !important;
}

.utl-ml-85 {
	margin-left: 5.3125em !important;
}

.utl-ml-90 {
	margin-left: 5.625em !important;
}

.utl-ml-95 {
	margin-left: 5.9375em !important;
}

.utl-ml-100 {
	margin-left: 6.25em !important;
}

/* margin X-axis */
.utl-mx-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}

.utl-mx-0 {
	margin-right: 0 !important;
	margin-left: 0 !important;
}

.utl-mx-5 {
	margin-right: 0.3125em !important;
	margin-left: 0.3125em !important;
}

.utl-mx-10 {
	margin-right: 0.625em !important;
	margin-left: 0.625em !important;
}

.utl-mx-15 {
	margin-right: 0.9375em !important;
	margin-left: 0.9375em !important;
}

.utl-mx-20 {
	margin-right: 1.25em !important;
	margin-left: 1.25em !important;
}

.utl-mx-25 {
	margin-right: 1.5625em !important;
	margin-left: 1.5625em !important;
}

.utl-mx-30 {
	margin-right: 1.875em !important;
	margin-left: 1.875em !important;
}

.utl-mx-35 {
	margin-right: 2.1875em !important;
	margin-left: 2.1875em !important;
}

.utl-mx-40 {
	margin-right: 2.5em !important;
	margin-left: 2.5em !important;
}

.utl-mx-45 {
	margin-right: 2.8125em !important;
	margin-left: 2.8125em !important;
}

.utl-mx-50 {
	margin-right: 3.125em !important;
	margin-left: 3.125em !important;
}

.utl-mx-55 {
	margin-right: 3.4375em !important;
	margin-left: 3.4375em !important;
}

.utl-mx-60 {
	margin-right: 3.75em !important;
	margin-left: 3.75em !important;
}

.utl-mx-65 {
	margin-right: 4.0625em !important;
	margin-left: 4.0625em !important;
}

.utl-mx-70 {
	margin-right: 4.375em !important;
	margin-left: 4.375em !important;
}

.utl-mx-75 {
	margin-right: 4.6875em !important;
	margin-left: 4.6875em !important;
}

.utl-mx-80 {
	margin-right: 5em !important;
	margin-left: 5em !important;
}

.utl-mx-85 {
	margin-right: 5.3125em !important;
	margin-left: 5.3125em !important;
}

.utl-mx-90 {
	margin-right: 5.625em !important;
	margin-left: 5.625em !important;
}

.utl-mx-95 {
	margin-right: 5.9375em !important;
	margin-left: 5.9375em !important;
}

.utl-mx-100 {
	margin-right: 6.25em !important;
	margin-left: 6.25em !important;
}

/* margin Y-axis */
.utl-my-auto {
	margin-top: auto !important;
	margin-bottom: auto !important;
}

.utl-my-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.utl-my-5 {
	margin-top: 0.3125em !important;
	margin-bottom: 0.3125em !important;
}

.utl-my-10 {
	margin-top: 0.625em !important;
	margin-bottom: 0.625em !important;
}

.utl-my-15 {
	margin-top: 0.9375em !important;
	margin-bottom: 0.9375em !important;
}

.utl-my-20 {
	margin-top: 1.25em !important;
	margin-bottom: 1.25em !important;
}

.utl-my-25 {
	margin-top: 1.5625em !important;
	margin-bottom: 1.5625em !important;
}

.utl-my-30 {
	margin-top: 1.875em !important;
	margin-bottom: 1.875em !important;
}

.utl-my-35 {
	margin-top: 2.1875em !important;
	margin-bottom: 2.1875em !important;
}

.utl-my-40 {
	margin-top: 2.5em !important;
	margin-bottom: 2.5em !important;
}

.utl-my-45 {
	margin-top: 2.8125em !important;
	margin-bottom: 2.8125em !important;
}

.utl-my-50 {
	margin-top: 3.125em !important;
	margin-bottom: 3.125em !important;
}

.utl-my-55 {
	margin-top: 3.4375em !important;
	margin-bottom: 3.4375em !important;
}

.utl-my-60 {
	margin-top: 3.75em !important;
	margin-bottom: 3.75em !important;
}

.utl-my-65 {
	margin-top: 4.0625em !important;
	margin-bottom: 4.0625em !important;
}

.utl-my-70 {
	margin-top: 4.375em !important;
	margin-bottom: 4.375em !important;
}

.utl-my-75 {
	margin-top: 4.6875em !important;
	margin-bottom: 4.6875em !important;
}

.utl-my-80 {
	margin-top: 5em !important;
	margin-bottom: 5em !important;
}

.utl-my-85 {
	margin-top: 5.3125em !important;
	margin-bottom: 5.3125em !important;
}

.utl-my-90 {
	margin-top: 5.625em !important;
	margin-bottom: 5.625em !important;
}

.utl-my-95 {
	margin-top: 5.9375em !important;
	margin-bottom: 5.9375em !important;
}

.utl-my-100 {
	margin-top: 6.25em !important;
	margin-bottom: 6.25em !important;
}

/* Utility padding */
.utl-p-0 {
	padding: 0 !important;
}

/* padding top */
.utl-pt-0 {
	padding-top: 0 !important;
}

.utl-pt-5 {
	padding-top: 0.3125em !important;
}

.utl-pt-10 {
	padding-top: 0.625em !important;
}

.utl-pt-15 {
	padding-top: 0.9375em !important;
}

.utl-pt-20 {
	padding-top: 1.25em !important;
}

.utl-pt-25 {
	padding-top: 1.5625em !important;
}

.utl-pt-30 {
	padding-top: 1.875em !important;
}

.utl-pt-35 {
	padding-top: 2.1875em !important;
}

.utl-pt-40 {
	padding-top: 2.5em !important;
}

.utl-pt-45 {
	padding-top: 2.8125em !important;
}

.utl-pt-50 {
	padding-top: 3.125em !important;
}

.utl-pt-55 {
	padding-top: 3.4375em !important;
}

.utl-pt-60 {
	padding-top: 3.75em !important;
}

.utl-pt-65 {
	padding-top: 4.0625em !important;
}

.utl-pt-70 {
	padding-top: 4.375em !important;
}

.utl-pt-75 {
	padding-top: 4.6875em !important;
}

.utl-pt-80 {
	padding-top: 5em !important;
}

.utl-pt-85 {
	padding-top: 5.3125em !important;
}

.utl-pt-90 {
	padding-top: 5.625em !important;
}

.utl-pt-95 {
	padding-top: 5.9375em !important;
}

.utl-pt-100 {
	padding-top: 6.25em !important;
}

/* padding bottom */
.utl-pb-0 {
	padding-bottom: 0 !important;
}

.utl-pb-5 {
	padding-bottom: 0.3125em !important;
}

.utl-pb-10 {
	padding-bottom: 0.625em !important;
}

.utl-pb-15 {
	padding-bottom: 0.9375em !important;
}

.utl-pb-20 {
	padding-bottom: 1.25em !important;
}

.utl-pb-25 {
	padding-bottom: 1.5625em !important;
}

.utl-pb-30 {
	padding-bottom: 1.875em !important;
}

.utl-pb-35 {
	padding-bottom: 2.1875em !important;
}

.utl-pb-40 {
	padding-bottom: 2.5em !important;
}

.utl-pb-45 {
	padding-bottom: 2.8125em !important;
}

.utl-pb-50 {
	padding-bottom: 3.125em !important;
}

.utl-pb-55 {
	padding-bottom: 3.4375em !important;
}

.utl-pb-60 {
	padding-bottom: 3.75em !important;
}

.utl-pb-65 {
	padding-bottom: 4.0625em !important;
}

.utl-pb-70 {
	padding-bottom: 4.375em !important;
}

.utl-pb-75 {
	padding-bottom: 4.6875em !important;
}

.utl-pb-80 {
	padding-bottom: 5em !important;
}

.utl-pb-85 {
	padding-bottom: 5.3125em !important;
}

.utl-pb-90 {
	padding-bottom: 5.625em !important;
}

.utl-pb-95 {
	padding-bottom: 5.9375em !important;
}

.utl-pb-100 {
	padding-bottom: 6.25em !important;
}

/* padding right */
.utl-pr-0 {
	padding-right: 0 !important;
}

.utl-pr-5 {
	padding-right: 0.3125em !important;
}

.utl-pr-10 {
	padding-right: 0.625em !important;
}

.utl-pr-15 {
	padding-right: 0.9375em !important;
}

.utl-pr-20 {
	padding-right: 1.25em !important;
}

.utl-pr-25 {
	padding-right: 1.5625em !important;
}

.utl-pr-30 {
	padding-right: 1.875em !important;
}

.utl-pr-35 {
	padding-right: 2.1875em !important;
}

.utl-pr-40 {
	padding-right: 2.5em !important;
}

.utl-pr-45 {
	padding-right: 2.8125em !important;
}

.utl-pr-50 {
	padding-right: 3.125em !important;
}

.utl-pr-55 {
	padding-right: 3.4375em !important;
}

.utl-pr-60 {
	padding-right: 3.75em !important;
}

.utl-pr-65 {
	padding-right: 4.0625em !important;
}

.utl-pr-70 {
	padding-right: 4.375em !important;
}

.utl-pr-75 {
	padding-right: 4.6875em !important;
}

.utl-pr-80 {
	padding-right: 5em !important;
}

.utl-pr-85 {
	padding-right: 5.3125em !important;
}

.utl-pr-90 {
	padding-right: 5.625em !important;
}

.utl-pr-95 {
	padding-right: 5.9375em !important;
}

.utl-pr-100 {
	padding-right: 6.25em !important;
}

/* padding left */
.utl-pl-0 {
	padding-left: 0 !important;
}

.utl-pl-5 {
	padding-left: 0.3125em !important;
}

.utl-pl-10 {
	padding-left: 0.625em !important;
}

.utl-pl-15 {
	padding-left: 0.9375em !important;
}

.utl-pl-20 {
	padding-left: 1.25em !important;
}

.utl-pl-25 {
	padding-left: 1.5625em !important;
}

.utl-pl-30 {
	padding-left: 1.875em !important;
}

.utl-pl-35 {
	padding-left: 2.1875em !important;
}

.utl-pl-40 {
	padding-left: 2.5em !important;
}

.utl-pl-45 {
	padding-left: 2.8125em !important;
}

.utl-pl-50 {
	padding-left: 3.125em !important;
}

.utl-pl-55 {
	padding-left: 3.4375em !important;
}

.utl-pl-60 {
	padding-left: 3.75em !important;
}

.utl-pl-65 {
	padding-left: 4.0625em !important;
}

.utl-pl-70 {
	padding-left: 4.375em !important;
}

.utl-pl-75 {
	padding-left: 4.6875em !important;
}

.utl-pl-80 {
	padding-left: 5em !important;
}

.utl-pl-85 {
	padding-left: 5.3125em !important;
}

.utl-pl-90 {
	padding-left: 5.625em !important;
}

.utl-pl-95 {
	padding-left: 5.9375em !important;
}

.utl-pl-100 {
	padding-left: 6.25em !important;
}

/* padding X-axis */
.utl-px-0 {
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.utl-px-5 {
	padding-right: 0.3125em !important;
	padding-left: 0.3125em !important;
}

.utl-px-10 {
	padding-right: 0.625em !important;
	padding-left: 0.625em !important;
}

.utl-px-15 {
	padding-right: 0.9375em !important;
	padding-left: 0.9375em !important;
}

.utl-px-20 {
	padding-right: 1.25em !important;
	padding-left: 1.25em !important;
}

.utl-px-25 {
	padding-right: 1.5625em !important;
	padding-left: 1.5625em !important;
}

.utl-px-30 {
	padding-right: 1.875em !important;
	padding-left: 1.875em !important;
}

.utl-px-35 {
	padding-right: 2.1875em !important;
	padding-left: 2.1875em !important;
}

.utl-px-40 {
	padding-right: 2.5em !important;
	padding-left: 2.5em !important;
}

.utl-px-45 {
	padding-right: 2.8125em !important;
	padding-left: 2.8125em !important;
}

.utl-px-50 {
	padding-right: 3.125em !important;
	padding-left: 3.125em !important;
}

.utl-px-55 {
	padding-right: 3.4375em !important;
	padding-left: 3.4375em !important;
}

.utl-px-60 {
	padding-right: 3.75em !important;
	padding-left: 3.75em !important;
}

.utl-px-65 {
	padding-right: 4.0625em !important;
	padding-left: 4.0625em !important;
}

.utl-px-70 {
	padding-right: 4.375em !important;
	padding-left: 4.375em !important;
}

.utl-px-75 {
	padding-right: 4.6875em !important;
	padding-left: 4.6875em !important;
}

.utl-px-80 {
	padding-right: 5em !important;
	padding-left: 5em !important;
}

.utl-px-85 {
	padding-right: 5.3125em !important;
	padding-left: 5.3125em !important;
}

.utl-px-90 {
	padding-right: 5.625em !important;
	padding-left: 5.625em !important;
}

.utl-px-95 {
	padding-right: 5.9375em !important;
	padding-left: 5.9375em !important;
}

.utl-px-100 {
	padding-right: 6.25em !important;
	padding-left: 6.25em !important;
}

/* padding Y-axis */
.utl-py-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.utl-py-5 {
	padding-top: 0.3125em !important;
	padding-bottom: 0.3125em !important;
}

.utl-py-10 {
	padding-top: 0.625em !important;
	padding-bottom: 0.625em !important;
}

.utl-py-15 {
	padding-top: 0.9375em !important;
	padding-bottom: 0.9375em !important;
}

.utl-py-20 {
	padding-top: 1.25em !important;
	padding-bottom: 1.25em !important;
}

.utl-py-25 {
	padding-top: 1.5625em !important;
	padding-bottom: 1.5625em !important;
}

.utl-py-30 {
	padding-top: 1.875em !important;
	padding-bottom: 1.875em !important;
}

.utl-py-35 {
	padding-top: 2.1875em !important;
	padding-bottom: 2.1875em !important;
}

.utl-py-40 {
	padding-top: 2.5em !important;
	padding-bottom: 2.5em !important;
}

.utl-py-45 {
	padding-top: 2.8125em !important;
	padding-bottom: 2.8125em !important;
}

.utl-py-50 {
	padding-top: 3.125em !important;
	padding-bottom: 3.125em !important;
}

.utl-py-55 {
	padding-top: 3.4375em !important;
	padding-bottom: 3.4375em !important;
}

.utl-py-60 {
	padding-top: 3.75em !important;
	padding-bottom: 3.75em !important;
}

.utl-py-65 {
	padding-top: 4.0625em !important;
	padding-bottom: 4.0625em !important;
}

.utl-py-70 {
	padding-top: 4.375em !important;
	padding-bottom: 4.375em !important;
}

.utl-py-75 {
	padding-top: 4.6875em !important;
	padding-bottom: 4.6875em !important;
}

.utl-py-80 {
	padding-top: 5em !important;
	padding-bottom: 5em !important;
}

.utl-py-85 {
	padding-top: 5.3125em !important;
	padding-bottom: 5.3125em !important;
}

.utl-py-90 {
	padding-top: 5.625em !important;
	padding-bottom: 5.625em !important;
}

.utl-py-95 {
	padding-top: 5.9375em !important;
	padding-bottom: 5.9375em !important;
}

.utl-py-100 {
	padding-top: 6.25em !important;
	padding-bottom: 6.25em !important;
}

/*
 * text classes
 */
.utl-col-red01 {
	color: red;
}

.utl-col-blu01 {
	color: #1050e2;
}

.utl-col-blu02 {
	color: #031f6f;
}

.utl-col-blu03 {
	color: #657bb5;
}

.utl-col-blu04 {
	color: #5c7faf;
}

.utl-col-blu05 {
	color: #234e89;
}

.utl-col-yel01 {
	color: #f2b724;
}

.utl-col-wht {
	color: #fff;
}

.utl-fwn {
	font-weight: 400 !important;
}

.utl-fwb {
	font-weight: 600 !important;
}

.utl-fw-100,
.utl-fwnsnsjp-thin {
	font-weight: 100 !important;
}

.utl-fw-300,
.utl-fwnsnsjp-light {
	font-weight: 300 !important;
}

.utl-fw-400,
.utl-fwnsnsjp-regular {
	font-weight: 400 !important;
}

.utl-fw-500,
.utl-fwnsnsjp-medium {
	font-weight: 500 !important;
}

.utl-fw-700,
.utl-fwnsnsjp-bold {
	font-weight: 700 !important;
}

.utl-fw-900,
.utl-fwnsnsjp-black {
	font-weight: 900 !important;
}

.utl-tal {
	text-align: left !important;
}

.utl-tar {
	text-align: right !important;
}

.utl-tac {
	text-align: center !important;
}

.utl-taj {
	text-align: justify !important;
}

/*
 * content display classes
 */
.utl-di {
	display: inline !important;
}

.utl-db {
	display: block !important;
}

.utl-dib {
	display: inline-block !important;
}

.utl-dflx {
	display: flex !important;
}

.utl-diflx {
	display: inline-flex !important;
}

/*
 * position classes
 */
.utl-rltv {
	position: relative;
}

.utl-absl {
	position: absolute;
}

.utl-fxd {
	position: fixed;
}

/*
 * link disable
 */
.utl-nolnk {
	pointer-events: none;
}

/*
 * anchor block
 */
.utl-anch-blk a, a.utl-anch-blk {
	display: block;
}

/*
 * text break
 */
.utl-br {
	display: inline-block;
}

.utl-br-all {
	word-break: break-all;
}

/*
 * text indent
 */
.utl-indent {
	text-indent: 1em;
}

.utl-neg-indent {
	margin-left: 1em;
	text-indent: -1em;
}

/*
 * image replacement
 */
.utl-ir {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

/*
 * mouse over action
 */
.utl-ovr {
	backface-visibility: hidden;
}

.is-load .utl-ovr {
	transition: opacity 450ms cubic-bezier(0.22, 1, 0.36, 1);
}

.ua-mbl .utl-ovr:active {
	opacity: 0.4;
}

body:not(.ua-mbl) .utl-ovr:hover {
	opacity: 0.4;
}

.utl-ovr-brght {
	backface-visibility: hidden;
}

.is-load .utl-ovr-brght {
	transition: filter 450ms cubic-bezier(0.22, 1, 0.36, 1);
}

.ua-mbl .utl-ovr-brght:active {
	filter: brightness(125%);
}

body:not(.ua-mbl) .utl-ovr-brght:hover {
	filter: brightness(125%);
}

/*
 * add pointer effect
 */
.utl-ptr {
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*
 * add inertial scroll to mobile device
 */
.utl-i-scroll {
	-webkit-overflow-scrolling: touch;
	overflow-scrolling: touch;
}

/*
 * scroll bar hidden
 */
.utl-scroll-bar-hdn {
	scrollbar-width: none;
	-ms-overflow-style: none;
}

.utl-scroll-bar-hdn::-webkit-scrollbar {
	display: none;
}

/*
 * change svg color
 */
.utl-chg-svg-col {
	fill: currentColor;
}

/*
 * clear fix
 */
.utl-cf::before, .utl-cf::after {
	content: '';
	display: table;
}

.utl-cf::after {
	clear: both;
}

/*
 * hide visually and from screen readers, but maintain layout
 */
.utl-invisible {
	visibility: hidden;
}

/*
 * hide visually and from screen readers
 */
.utl-hdn {
	display: none !important;
}

@media screen and (max-width: 670px) {
	.utl-mbl-hdn {
		display: none !important;
	}
}

@media screen and (min-width: 671px) {
	.utl-pc-hdn {
		display: none !important;
	}
}

@media screen and (min-width: 671px) and (max-width: 1050px) {
	.utl-tblt-hdn {
		display: none !important;
	}
}

/*
 * hide only visually, but have it available for screen readers
 */
.utl-visually-hdn, .utl-visually-hdn-foc {
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
	overflow: hidden;
	position: absolute;
	clip: rect(0 0 0 0);
}

/*
 * extends the .utl-visually-hdn class to allow the element
 * to be focusable when navigated to via the keyboard
 */
.utl-visually-hdn-foc:active, .utl-visually-hdn-foc:focus {
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	position: static;
	clip: auto;
}

/* ==============================================================================================
   Print styles
   ============================================================================================== */
@media print {
	*, *::before, *::after, *::first-letter, *::first-line {
		color: #000 !important;
		/* Black prints faster */
		text-shadow: none !important;
		box-shadow: none !important;
		background: transparent !important;
	}
	a, a:visited {
		text-decoration: underline;
	}
	a[href]::after {
		content: ' (" attr(href) ")';
	}
	a[href^="#"]::after, a[href^="javascript:"]::after {
		content: '';
	}
	abbr[title]::after {
		content: ' (" attr(title) ")';
	}
	pre {
		white-space: pre-wrap !important;
	}
	pre,
	blockquote {
		border: solid 1px #999;
		page-break-inside: avoid;
	}
	thead {
		display: table-header-group;
	}
	tr, img {
		page-break-inside: avoid;
	}
	img {
		max-width: 100% !important;
	}
	p, h2, h3 {
		orphans: 3;
		widows: 3;
	}
	h2, h3 {
		page-break-after: avoid;
	}
}
