// Print styles
// =============================================================================
@media print {
	* {
		&,
		&::before,
		&::after,
		&::first-letter,
		&::first-line {
			color: #000 !important; /* Black prints faster */
			text-shadow: none !important;
			box-shadow: none !important;
			background: transparent !important;
		}
	}
	
	a {
		&,
		&:visited {
			text-decoration: underline;
		}
		&[href]::after {
			content: ' (" attr(href) ")';
		}
		&[href^="#"]::after,
		&[href^="javascript:"]::after {
			content: '';
		}
	}
	
	abbr[title]::after {
		content: ' (" attr(title) ")';
	}
	
	pre {
		white-space: pre-wrap !important;
	}
	
	pre,
	blockquote {
		border: solid 1px #999;
		page-break-inside: avoid;
	}
	
	thead {
		display: table-header-group;
	}
	
	tr, img {
		page-break-inside: avoid;
	}
	
	img {
		max-width: 100% !important;
	}
	
	p, h2, h3 {
		orphans: 3;
		widows: 3;
	}
	
	h2, h3 {
		page-break-after: avoid;
	}
}